$(function() {
  /* ヘッダー背景色アニメーション */
  if ($('.header-area').height() < $(this).scrollTop()) {
    $('.header-area').addClass('jsBackgroundHeader');
  } else {
    $('.header-area').removeClass('jsBackgroundHeader');
  }
});
$(window).on('scroll', function() {
  /* ヘッダー背景色アニメーション */
  if ($('.header-area').height() < $(this).scrollTop()) {
    $('.header-area').addClass('jsBackgroundHeader');
  } else {
    $('.header-area').removeClass('jsBackgroundHeader');
  }
});
