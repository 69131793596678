import 'jQuery';
import ScrollReveal from 'scrollreveal';

/* SPメニュー */
$('.sp-nav-button').on('click', function() {
  $('.sp-nav-button, .nav-toggle, .sp-nav, .sp-nav-list, .back-gray, .language-btn-area-sp').toggleClass('show');
});

$(function() {
  /* スクロール */
  ScrollReveal().reveal('.jsScroll', {
    origin: 'bottom',
    distance: '30px',
    duration: 1500,
    delay: 0,
  });

  ScrollReveal().reveal('.jsScrollDelay', {
    origin: 'bottom',
    distance: '30px',
    duration: 1500,
    delay: 500,
  });

  ScrollReveal().reveal('.jsScrollDelayTopcontents', {
    origin: 'bottom',
    distance: '30px',
    duration: 1500,
    delay: 1000,
  });

  /* ナビゲーション(SP) */
  $('.sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link,.sub-menu').each(function() {
    const $href = $(this).attr('href');
    if (location.pathname.indexOf('en/diversity') >= 0) {
      if ($href !== '/en/') {
        if (location.pathname.indexOf($href) !== -1) {
          $(this)
            .parent()
            .addClass('current');
        } else {
          $(this)
            .parent()
            .removeClass('current');
        }
      } else if ($href === '/en/') {
        if (location.pathname === $href) {
          $(this)
            .parent()
            .addClass('current');
        } else {
          $(this)
            .parent()
            .removeClass('current');
        }
      }
    } else {
      if (location.pathname.indexOf('diversity')) {
        if (location.pathname === $href) {
          $(this)
            .parent()
            .addClass('current');
        } else {
          $(this)
            .parent()
            .removeClass('current');
        }
      } else if ($href !== '/') {
        if (location.pathname.indexOf($href) !== -1) {
          $(this)
            .parent()
            .addClass('current');
        } else {
          $(this)
            .parent()
            .removeClass('current');
        }
      } else if ($href === '/') {
        if (location.pathname === $href) {
          $(this)
            .parent()
            .addClass('current');
        } else {
          $(this)
            .parent()
            .removeClass('current');
        }
      }
    }
  });

  const path = $(location).attr('pathname');
  // 英語ディレクトリで翻訳がなかった際に表示を切り替え、翻訳なしの文言をフェードアウト
  if (path.indexOf('en') >= 0) {
    $(':lang(ja).language-atention')
      .css('display', 'block')
      .fadeIn()
      .queue(function() {
        setTimeout(function() {
          $('.language-atention').dequeue();
        }, 5000);
      });
    $('.language-atention').fadeOut();
    $(':lang(ja).language').css('display', 'block');
    $(':lang(ja).language_en').css('display', 'none');
  }
  // 言語切り替えボタンが押下されたとき
  // パスにenがあればenを削除して日本語ディレクトリへ
  // それ以外は英語ディレクトリへ
  $('.language-menu').on('click', function() {
    $('.dropdown__lists').toggleClass('active');
  });
  $('.sp-header-menu-about').on('click', function() {
    $('.header-menu_lists-about').toggleClass('active');
    $('.sp-header-menu-about').toggleClass('check');
  });
  $('.sp-header-menu-sustainability').on('click', function() {
    $('.header-menu_lists-sustainability').toggleClass('active');
    $('.sp-header-menu-sustainability').toggleClass('check');
  });
  $('.sp-header-menu-solution').on('click', function() {
    $('.header-menu_lists-solution').toggleClass('active');
    $('.sp-header-menu-solution').toggleClass('check');
  });

  // ヘッダー2階層の表示アニメーション
  $('.about-dropdown-list,.dropdown-about')
    .on('mouseover', function() {
      $('.Header-backdrop-about').addClass('Header-backdrop_visible');
      $('.text-link-about').addClass('selected');
    })
    .on('mouseout', function() {
      $('.Header-backdrop-about').removeClass('Header-backdrop_visible');
      $('.text-link-about').removeClass('selected');
    });
  $('.sustainability-dropdown-list,.dropdown-sustainability')
    .on('mouseover', function() {
      $('.Header-backdrop-sustainability').addClass('Header-backdrop_visible');
      $('.text-link-sustainability').addClass('selected');
    })
    .on('mouseout', function() {
      $('.Header-backdrop-sustainability').removeClass('Header-backdrop_visible');
      $('.text-link-sustainability').removeClass('selected');
    });
  $('.solution-dropdown-list,.dropdown-solution')
    .on('mouseover', function() {
      $('.Header-backdrop-solution').addClass('Header-backdrop_visible');
      $('.text-link-solution').addClass('selected');
    })
    .on('mouseout', function() {
      $('.Header-backdrop-solution').removeClass('Header-backdrop_visible');
      $('.text-link-solution').removeClass('selected');
    });

  const headerMenuItems = document.querySelectorAll(
    '.about-dropdown-list,.sustainability-dropdown-list,.solution-dropdown-list'
  );
  const headerContents = document.querySelectorAll('.dropdown_area');
  headerMenuItems.forEach(headerMenuItem => {
    // マウスホバー時に発火するイベントは「mouseover」を使う。
    headerMenuItem.addEventListener('mouseover', () => {
      document.getElementById(headerMenuItem.dataset.id).classList.add('active');
    });
    headerMenuItem.addEventListener('mouseout', () => {
      headerContents.forEach(headerContent => {
        headerContent.classList.remove('active');
      });
    });
  });

  // URLの先頭に/en/がある場合のみ削除
  // 日本語ページへの切り替え
  $('.language-btn').on('click', function() {
    const path = $(location).attr('pathname');
    if (path.indexOf('/en/') === 0) {
      const str = path.replace(/^\/en\//, '/');
      window.location.href = str;
    }
  });

  // 英語ページへの切り替え
  $('.language-btn_en').on('click', function() {
    const path = $(location).attr('pathname');
    if (path.indexOf('/en/') === 0) {
    } else {
      const url = '/en' + path;
      window.location.href = url;
    }
  });
});
